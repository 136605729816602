<template>
  <span class="edit-user-button" v-if="hasPerm('auth.change_user')">
    <a href @click.prevent="showModal" class="btn btn-xs btn-secondary">
      <i class="fa fa-pen"></i>
      Modifier le mot de passe
    </a>
    <b-modal
      dialog-class="modal-md"
      :id="getId()"
      :ok-disabled="formInvalid"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Enregistrer"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Modifier un mot de passe</b>
      </template>
      <div v-if="user" style="margin-bottom: 10px">
        Voulez-vous changer le mot de passe de {{ user.fullName() }}?
      </div>
      <div v-if="errors.length" class="error-text">
        <div v-for="err of errors" :key="err">
          {{ err }}
        </div>
      </div>
      <b-row>
        <b-col>
          <div class="float-right">
            <a href @click.prevent="showPassword = !showPassword">
              <i class="fa fa-eye" v-if="showPassword"></i>
              <i class="fa fa-eye-slash" v-else></i>
            </a>
          </div>
          <b-form-group
            id="password-group"
            label="Nouveau mot de passe"
            label-for="password"
            description="Au moins 8 caractères avec une majuscule, une minuscule, un chiffre et un caractère spécial"
          >
            <b-form-input
              id="password"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="confirm-password-group"
            label="Confirmation du mot de passe"
            label-for="confirm-password"
          >
            <b-form-input
              id="confirm-password"
              v-model="confirmPassword"
              :type="showPassword ? 'text' : 'password'"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { getRandomId } from '@/utils/random'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'ChangeUserPasswordButton',
  mixins: [BackendMixin],
  props: {
    user: Object,
  },
  data() {
    return {
      randomId: getRandomId(),
      errors: [],
      password: '',
      confirmPassword: '',
      showPassword: false,
    }
  },
  computed: {
    formInvalid() {
      return (this.firstName === '') || (this.lastName === null)
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-edit-user-button' + this.randomId
    },
    async onSave() {
      this.errors = []
      const data = {
        user: this.user.id,
        password: this.password,
        confirm_password: this.confirmPassword,
      }
      let backendApi = new BackendApi('patch', '/users/api/user-change-password/')
      try {
        await backendApi.callApi(data)
        await this.addSuccess('L\'utilisateur a été modifié')
        this.$bvModal.hide(this.getId())
        this.$emit('done', { user: this.user, })
      } catch (err) {
        if (err.data && err.data.password) {
          this.errors = err.data.password
        } else {
          this.errors = [this.getErrorText(err)]
        }
      }
    },
  },
}
</script>

<style scoped>
.float-right {
  float: right;
}
</style>
