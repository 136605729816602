<template>
  <span v-b-tooltip="datetimeTime" class="date-time-value">
    {{ datetimeDate }}
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { dateToString } from '@/filters/texts'

export default {
  name: 'DateTimeValue',
  props: {
    datetime: [Date, String],
    defaultText: {
      type: String,
      default: '-',
    },
  },
  computed: {
    datetimeDate() {
      if (this.datetime) {
        return dateToString(this.datetime, 'DD/MM/YYYY')
      }
      return this.defaultText
    },
    datetimeTime() {
      if (this.datetime) {
        return dateToString(this.datetime, 'HH:mm')
      }
      return ''
    },
  },
}
</script>
<style scoped lang="less">
</style>
