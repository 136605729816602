<template>
  <span class="add-adhesion-button" v-if="canView">
    <a href @click.prevent="removeToGroup" class="btn btn-xs btn-secondary" v-if="isMember()">
      <i class="fas fa-minus-circle"></i>
      Retirer du groupe
    </a>
    <a href @click.prevent="addToGroup" class="btn btn-xs btn-secondary" v-else>
      <i class="fa fa-plus"></i>
      Ajouter au groupe
    </a>
  </span>
</template>

<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeStaffUser } from '@/types/users'
import { BackendApi } from '@/utils/http'

export default {
  name: 'edit-user-groups-button',
  mixins: [BackendMixin],
  props: {
    member: {
      type: Boolean,
      default: null,
    },
    id: {
      type: Number,
      default: 0,
    },
    selectedGroup: Object,
    user: Object,
    userGroups: Array,
  },
  data() {
    return {
      isActive: null,
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    isMember() {
      return this.member
    },
    async addToGroup() {
      this.isActive = this.user.isActive
      this.errorText = ''
      this.userGroups.push(this.selectedGroup)
      const addGroups = this.userGroups.map(
        group => {
          return group.id
        }
      )
      const data = {
        groups: addGroups,
        is_active: this.isActive,
      }
      let backendApi = new BackendApi('patch', '/users/api/users/' + this.id + '/')
      try {
        let resp = await backendApi.callApi(data)
        const user = makeStaffUser(resp.data)
        await this.addSuccess('L\'utilisateur a été ajouté au groupe')
        this.$emit('done', { user: user, })
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    async removeToGroup() {
      this.isActive = this.user.isActive
      this.errorText = ''
      let ArrayFilter = this.userGroups.filter(elt => elt.id !== this.selectedGroup.id)
      const removeGroup = ArrayFilter.map(
        group => {
          return group.id
        }
      )
      const data = {
        groups: removeGroup,
        is_active: this.isActive,
      }
      let backendApi = new BackendApi('patch', '/users/api/users/' + this.id + '/')
      try {
        let resp = await backendApi.callApi(data)
        const user = makeStaffUser(resp.data)
        await this.addSuccess('L\'utilisateur a été retiré du groupe')
        this.$emit('done', { user: user, })
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
  },
  computed: {
    canView() {
      return this.hasPerm('auth.change_user')
    },
  },
}
</script>

<style scoped>

</style>
