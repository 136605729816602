<template>
  <span class="delete-user-button" v-if="hasPerm('auth.change_user')">
    <a href @click.prevent="showModal" class="btn btn-xs btn-secondary">
      <i class="fas fa-thumbs-up"></i>
      Activer
    </a>
    <b-modal
      dialog-class="modal-md"
      :id="getId()"
      @ok.prevent="onEnabled"
      ok-variant="primary"
      cancel-title="Non"
      ok-title="Oui"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Activer cet utilisateur ?</b>
      </template>
      <b-row>
        <b-col>
          Êtes-vous sûr de vouloir activer {{ user.fullName() }}? <br />
          L'utilisateur pourra de nouveau accéder à Millibase.net
        </b-col>
      </b-row>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
    </b-modal>
  </span>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import { makeStaffUser } from '@/types/users'

export default {
  name: 'ActivateUserButton',
  mixins: [BackendMixin],
  props: {
    user: Object,
  },
  data() {
    return {
      randomId: getRandomId(),
      errorText: '',
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-activate-user-button' + this.randomId
    },
    async onEnabled() {
      this.errorText = ''
      const data = {
        user: this.user.id,
      }
      let backendApi = new BackendApi('patch', '/users/api/reactivate-user/')
      try {
        await backendApi.callApi(data)
        await this.addSuccess('L\'utilisateur a été activé')
        this.$bvModal.hide(this.getId())
        this.$emit('done')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
  },
}
</script>

<style scoped>

</style>
