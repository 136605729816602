<template>
  <span class="edit-user-button" v-if="hasPerm('auth.change_user')">
    <a href @click.prevent="showModal" class="btn btn-xs btn-secondary" v-if="editMode">
      <i class="fa fa-pen"></i>
      Modifier
    </a>
    <a href @click.prevent="showModal" class="btn btn-sm btn-secondary" v-else>
      <i class="fa fa-plus"></i>
      Ajouter
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      :ok-disabled="formInvalid"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Enregistrer"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Modifier un utilisateur</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="first-name-group"
            label="Prenom"
            label-for="first-name"
          >
            <b-form-input
              id="first-name"
              v-model="firstName"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="last-name-group"
            label="Nom"
            label-for="last-name"
          >
            <b-form-input
              id="last-name"
              v-model="lastName"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="email-group"
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="email"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-for="eltGroups of listOfGroups" :key="eltGroups.id" class="group-item">
         <b-col v-if="eltGroups.groups.length">
          <b-row>
            <b-col>
              <div class="group-header">
                {{ eltGroups.name }}
                <span class="badge badge-light" v-if="eltGroups.help">{{ eltGroups.help }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <check-box-select
                :id="'' + eltGroups.id"
                :choices="eltGroups.groups"
                :initial-value="initialGroups"
                @changed="onSelectedGroupsChanged(eltGroups, $event)"
                inline
                box
                class="group-selector"
              >
              </check-box-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import { BackendMixin } from '@/mixins/backend'
import { makeStaffUser, makeUsersGroups } from '@/types/users'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'

export default {
  name: 'edit-user-button',
  components: { CheckBoxSelect, },
  mixins: [BackendMixin],
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: null,
      allGroups: [],
      randomId: getRandomId(),
      errorText: '',
      firstName: '',
      lastName: '',
      email: '',
      selectedGroups: [],
      isActive: false,
      lastLogin: null,
    }
  },
  computed: {
    formInvalid() {
      return (this.firstName === '') || (this.lastName === null)
    },
    editMode() {
      return this.id !== 0
    },
    fullGroups() {
      return this.allGroups.filter(group => group.name.indexOf('Gestion totale') === 0)
    },
    readGroups() {
      return this.allGroups.filter(group => group.name.indexOf('Accès') === 0)
    },
    writeGroups() {
      return this.allGroups.filter(group => group.name.indexOf('Gestion') === 0).filter(
        group => group.name.indexOf('Gestion totale') < 0
      )
    },
    otherGroups() {
      return this.allGroups.filter(group => group.name.indexOf('Accès') !== 0 && group.name.indexOf('Gestion') !== 0)
    },
    listOfGroups() {
      return [
        { id: 1, name: 'Droits en lecture', groups: this.readGroups, help: 'lecture seule', },
        { id: 2, name: 'Droits en écriture', groups: this.writeGroups, help: '', },
        { id: 3, name: 'Autres', groups: this.otherGroups, help: '', },
        { id: 4, name: 'Gestion totale', groups: this.fullGroups, help: 'Dispose de tous les droits', }
      ]
    },
    initialGroups() {
      return (this.user) ? this.user.groups : []
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
      if (this.id !== 0) {
        this.loadUser()
      }
      this.loadUsersGroups()
    },
    getId() {
      return 'bv-modal-edit-user-button' + this.randomId
    },
    onSelectedGroupsChanged(eltGroups, event) {
      // Conserve les éléments sélectionnés des autres groupes
      const current = [].concat(this.selectedGroups)
      this.selectedGroups = []
      const groupIds = eltGroups.groups.map(elt => elt.id)
      for (const elt of current) {
        if (groupIds.indexOf(elt.id) < 0) {
          this.selectedGroups.push(elt)
        }
      }
      // Ajoute les éléments sélectionnés du groupe cliqué
      this.selectedGroups = this.selectedGroups.concat(event.choices)
    },
    async onSave() {
      const groupIds = this.selectedGroups.map(elt => elt.id)
      this.errorText = ''
      if (this.id !== 0) {
        const data = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          is_active: this.isActive,
          groups: groupIds,
        }
        let backendApi = new BackendApi('patch', '/users/api/users/' + this.id + '/')
        try {
          let resp = await backendApi.callApi(data)
          this.user = makeStaffUser(resp.data)
          await this.addSuccess('L\'utilisateur a été modifié')
          this.$bvModal.hide(this.getId())
          this.$emit('done', { user: this.user, })
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      } else {
        const data = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          groups: groupIds,
        }
        let backendApi = new BackendApi('post', '/users/api/users/')
        try {
          let resp = await backendApi.callApi(data)
          const user = makeStaffUser(resp.data)
          await this.addSuccess('L\'utilisateur a été ajouté')
          this.$bvModal.hide(this.getId())
          this.$emit('done', { user: user, })
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
    async loadUsersGroups() {
      this.allGroups = []
      let backendApi = new BackendApi('get', '/users/api/users-groups/')
      try {
        let resp = await backendApi.callApi()
        this.allGroups = resp.data.map(elt => makeUsersGroups(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadUser() {
      this.user = null
      let backendApi = new BackendApi('get', '/users/api/users/' + this.id + '/')
      try {
        let resp = await backendApi.callApi()
        this.user = makeStaffUser(resp.data)
        this.firstName = this.user.firstName
        this.lastName = this.user.lastName
        this.email = this.user.email
        this.isActive = this.user.isActive
        this.lastLogin = this.user.lastLogin
        await this.loadUsersGroups()
        this.selectedGroups = this.user.groups
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
.group-header {
  font-weight: bold;
  padding-bottom: 3px;
  margin-bottom: 5px;
  border-bottom: solid 1px #000;
}
.group-item {
  margin-bottom: 20px;
}
</style>
