<template>
  <span class="delete-user-button" v-if="hasPerm('auth.delete_user')">
    <a href @click.prevent="showModal" class="btn btn-xs btn-secondary">
      <i class="fas fa-trash-alt"></i>
      Désactiver
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      @ok.prevent="onDisable"
      ok-variant="primary"
      cancel-title="Non"
      ok-title="Oui"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Êtes-vous sûr de vouloir désactiver cet utilisateur ?</b>
      </template>
      <b-row>
        <b-col>
          L'utilisateur <span v-if="user">{{ user.fullName() }}</span> ne pourra plus accéder à Millibase.net
        </b-col>
      </b-row>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
    </b-modal>
  </span>
</template>

<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeStaffUser, makeUsersGroups } from '@/types/users'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'

export default {
  name: 'delete-user-button',
  mixins: [BackendMixin],
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: null,
      groups: [],
      randomId: getRandomId(),
      errorText: '',
      isActive: null,
      firstName: null,
      lastName: null,
      email: null,
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
      if (this.id !== 0) {
        this.loadUser()
      }
      this.loadUsersGroups()
    },
    getId() {
      return 'bv-modal-delete-user-button' + this.randomId
    },
    async onDisable() {
      const groups = this.user.groups.map(
        group => {
          if (this.user.groups !== null) {
            return group.id
          } else {
            return null
          }
        }
      ).filter(elt => elt !== null)
      this.errorText = ''
      const data = {
        is_active: false,
        groups: groups,
      }
      let backendApi = new BackendApi('patch', '/users/api/users/' + this.id + '/')
      try {
        let resp = await backendApi.callApi(data)
        const user = makeStaffUser(resp.data)
        await this.addSuccess('L\'utilisateur a été supprimé')
        this.$bvModal.hide(this.getId())
        this.$emit('done', { user: user, })
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    async loadUsersGroups() {
      this.groups = []
      let backendApi = new BackendApi('get', '/users/api/users-groups/')
      try {
        let resp = await backendApi.callApi()
        this.groups = resp.data.map(elt => makeUsersGroups(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadUser() {
      this.user = null
      let backendApi = new BackendApi('get', '/users/api/users/' + this.id + '/')
      try {
        let resp = await backendApi.callApi()
        this.user = makeStaffUser(resp.data)
        this.isActive = this.user.isActive
        this.firstName = this.user.firstName
        this.lastName = this.user.lastName
        this.email = this.user.email
        await this.loadUsersGroups()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>

</style>
