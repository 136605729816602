<template>
  <div class="users" v-if="canView">
    <page-header title="Gestion des utilisateurs" icon="fa fa-users-cog"></page-header>
    <div v-if="isLocked" class="warning-header">
      <b-row>
        <b-col>
          <div class="warning-header-text">
            L'accès à la liste des utilisateurs nécessite de saisir à nouveau votre mot de passe.
          </div>
        </b-col>
        <b-col cols="5">
          <b-form-group
            label="Votre mot de passe"
            label-for="password"
          >
            <b-form-input
              :type="showPassword ? 'text' : 'password'"
              id="password"
              v-model="password"
            >
            </b-form-input>
            <b-row>
              <b-col class="form-help">
                Saisissez votre mot de passe pour accéder à cette fonctionnalité
              </b-col>
              <b-col cols="2" class="text-right">
                <a href @click.prevent="showPassword = !showPassword">
                  <i class="fa fa-eye" v-if="showPassword"></i>
                  <i class="fa fa-eye-slash" v-else></i>
                </a>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
        <b-col cols="2" class="text-right">
          <div class="warning-header-button">
            <a
              href
              class="btn btn-primary"
              :class="{ disabled: password === '', }"
              @click.prevent="unlock()">
              Déverrouiller
            </a>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-row class="row-header">
        <b-col cols="2"><b>Utilisateur</b></b-col>
        <b-col cols="2"><b>Dernière connexion</b></b-col>
        <b-col cols="4"><b>Groupes</b></b-col>
        <b-col cols="2" class="text-right">
          <b-form-group
            id="users-groups-group"
            label-for="users-groups"
            description="filtrer par droits d'accès"
            >
            <b-form-select
              id="users-groups"
              v-model="selectedGroup"
            >
              <b-form-select-option :value="null">
                Tous
              </b-form-select-option>
              <b-form-select-option
                :value="group"
                v-for="group in usersGroups"
                :key="group.id"
              >
                {{ group.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="2" class="text-right">
          <edit-user-button @done="onUserEdited"></edit-user-button>
        </b-col>
      </b-row>
      <div v-for="elt of listsOfUsers" :key="elt.index" class="users-list">
        <div v-if="elt.users.length > 0">
          <b-row class="row-header">
            <b-col>
              <h3>
                <counter-label
                  :label="getSectionTitle(elt.index)"
                  :label-n="getSectionTitlePlural(elt.index)"
                  :counter="elt.users.length">
                </counter-label>
              </h3>
            </b-col>
          </b-row>
          <b-row class="row-line" v-for="user of elt.users" :key="user.id" :class="inactiveClass(user)">
            <b-col cols="2">
              {{ user.fullName() }}
              <div class="user-email">{{ user.email }}</div>
            </b-col>
            <b-col cols="2" class="small-text">
              <date-time-value :datetime="user.lastLogin" defaultText="Pas encore connecté">
              </date-time-value>
            </b-col>
            <b-col cols="6">
              <div v-if="user.isActive">
                <span
                  v-for="userGroup of user.groups" :key="userGroup.id"
                  class="user-group" :class="userGroupClass(userGroup)"
                >
                {{ userGroup.name }}
                </span>
              </div>
            </b-col>
            <b-col cols="2" class="text-right">
              <span v-if="hasFilter()">
                <edit-user-groups-button
                  :user="user"
                  :userGroups="user.groups"
                  :id="user.id"
                  :selectedGroup="selectedGroup"
                  :member="filterGroupsMembers(selectedGroup, user)"
                  @done="onUserEdited"
                >
                </edit-user-groups-button>
              </span>
              <span v-else>
                <div v-if="user.isActive">
                  <edit-user-button :id="user.id" @done="onUserEdited"></edit-user-button>
                </div>
                <div v-if="!user.isActive">
                  <activate-user-button :user="user" @done="onLoaded"></activate-user-button>
                </div>
                <div v-if="user.isActive">
                  <delete-user-button :id="user.id" @done="onUserEdited"></delete-user-button>
                </div>
                <div v-if="user.isActive">
                  <change-user-password-button :user="user"></change-user-password-button>
                </div>
              </span>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import CounterLabel from '@/components/Controls/CounterLabel'
import DateTimeValue from '@/components/Controls/DateTimeValue'
import PageHeader from '@/components/Layout/PageHeader'
import EditUserButton from '@/components/Users/EditUserButton'
import DeleteUserButton from '@/components/Users/DeleteUserButton'
import EditUserGroupsButton from '@/components/Users/EditUserGroupsButton'
import ChangeUserPasswordButton from '@/components/Users/ChangeUserPasswordButton'
import ActivateUserButton from '@/components/Users/ActivateUserButton'
import { BackendApi } from '@/utils/http'
import { makeUsersGroups, makeStaffUser } from '@/types/users'
import { existsIn } from '@/utils/arrays'

export default {
  name: 'users',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    DateTimeValue,
    EditUserButton,
    DeleteUserButton,
    EditUserGroupsButton,
    PageHeader,
    ChangeUserPasswordButton,
    ActivateUserButton,
  },
  data() {
    return {
      usersGroups: [],
      users: [],
      selectedGroup: null,
      password: '',
      isLocked: true,
      showPassword: false,
    }
  },
  computed: {
    canView() {
      return this.hasAllPerms(['auth.view_user', 'auth.view_group'])
    },
    activeUsers() {
      return this.users.filter(user => user.isActive)
    },
    filteredUsers() {
      if (this.selectedGroup) {
        return this.activeUsers.filter(user => this.filterGroupsMembers(this.selectedGroup, user))
      }
      return this.activeUsers
    },
    ignoredUsers() {
      if (this.selectedGroup) {
        return this.activeUsers.filter(user => !this.filterGroupsMembers(this.selectedGroup, user))
      }
      return this.users.filter(user => !user.isActive)
    },
    listsOfUsers() {
      return [this.filteredUsers, this.ignoredUsers].map((elt, index) => {
        return { index: index, users: elt, }
      })
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadUsersGroups()
      await this.loadUsersList()
    },
    async loadUsersGroups() {
      this.usersGroups = []
      let backendApi = new BackendApi('get', '/users/api/users-groups/')
      try {
        let resp = await backendApi.callApi()
        this.usersGroups = resp.data.map(elt => makeUsersGroups(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadUsersList() {
      this.users = []
      let backendApi = new BackendApi('get', '/users/api/users/')
      try {
        let resp = await backendApi.callApi()
        this.users = resp.data.map(elt => makeStaffUser(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    inactiveClass(user) {
      if (user.isActive === false) {
        return 'inactive'
      } else {
        return ''
      }
    },
    getSectionTitle(eltIndex) {
      if (this.hasFilter()) {
        const level = (eltIndex === 0) ? 'Membre du groupe' : 'Non-membre du groupe'
        return level + ' "' + this.selectedGroup.name + '"'
      } else {
        return (eltIndex === 0) ? 'Utilisateur actif' : 'Compte inactif'
      }
    },
    getSectionTitlePlural(eltIndex) {
      if (this.hasFilter()) {
        const level = (eltIndex === 0) ? 'Membres du groupe' : 'Non-membres du groupe'
        return level + ' "' + this.selectedGroup.name + '"'
      } else {
        return (eltIndex === 0) ? 'Utilisateurs actifs' : 'Comptes inactifs'
      }
    },
    filterGroupsMembers(selectedGroup, user) {
      return existsIn([selectedGroup.id], user.groups.map(elt => elt.id))
    },
    hasFilter() {
      return this.selectedGroup !== null
    },
    onUserEdited() {
      this.loadUsersGroups()
      this.loadUsersList()
    },
    userGroupClass(group) {
      if (group.name === 'Gestion totale') {
        return 'group-warning'
      }
      return ''
    },
    async unlock() {
      let backendApi = new BackendApi('post', '/users/api/user-check-password/')
      try {
        const data = { password: this.password, }
        let resp = await backendApi.callApi(data)
        if (resp.data.ok) {
          this.isLocked = false
        } else {
          await this.addError('Le mot de passe ne correspond pas')
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
.inactive{
  color: #888;
}
.user-email {
  font-size: 12px;
}
.user-group {
  margin-bottom: 5px;
  margin-right: 2px;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  border: solid 1px #000;
}
.user-group.group-warning {
  background: #c84444;
  color: #fff;
}
.users-list {
  margin-bottom: 30px;
}
.warning-header {
  padding: 10px;
  border: solid 1px #ccc;
  margin-top: 20px;
}
.warning-header-text {
  padding: 25px;
  font-weight: bold;
  background: #efcb64;
}
.warning-header-button {
  padding-top: 32px;
}
.form-help {
  color: #888;
  font-size: 14px;
}
</style>
